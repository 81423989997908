<template>
  <div class="flex flex-row flex-wrap gap-1 items-center px-1">
    <div
      v-for="{ text, classes } in labelObjects"
      :key="text"
      :class="[classes, 'font-display font-medium leading-none [text-box:trim-both_cap_alphabetic] [font-size:min(6cqw,14px)] text-xs whitespace-nowrap rounded-tl rounded-br -skew-x-12 tracking-wide px-[0.6em] py-[0.6em]']"
    >
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductLabel } from '~/types/product'

const props = defineProps({
  labels: {
    type: Array as () => ProductLabel[],
    required: false,
    default: () => ([])
  }
})

const labelStyles: { [K in ProductLabel]: string } = {
  UUTUUS: 'bg-green-500 text-white',
  OUTLET: 'bg-pine-950 text-[#C9B296]',
  KOTIMAINEN: 'bg-[#012F6C] text-white',
  POISTOTUOTE: 'bg-red text-white',
  ENNAKKOTILAA: 'bg-gold-300 text-white'
}

const labelObjects = computed(() => (props.labels || []).map((label) => {
  return {
    text: label,
    classes: labelStyles[label.toUpperCase() as ProductLabel] || 'bg-grey-100 text-black'
  }
}))
</script>
