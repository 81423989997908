<template>
  <NuxtLink :to="`/product/${product.slug}`" :class="cssClasses" data-test="product-tile">
    <span class="block relative">
      <ProductLabels :labels="product.labels" class="absolute bottom-0 left-0 z-1 w-full justify-center" />
      <ProductDiscountBadge :discount-percent="discountPercent" class="absolute right-1 @[280px]:right-4 top-1 @[280px]:top-4 z-1" />
      <img
        :src="getMainImageUrl(product, 'small')"
        :alt="product.name.variation"
        loading="lazy"
        class="product-image @[280px]:p-3 rounded w-full aspect-square object-contain bg-white group-hover:brightness-105 transition-[filter] duration-200"
      >
    </span>

    <div class="flex-1 h-full flex flex-col items-center gap-3 p-4 pt-0">
      <div class="h-3 flex gap-1.5 items-center">
        <ReviewStars :score="reviewScore" class="h-[.75em]" />
        <div v-if="reviewAmount" class="mt-px text-xxs/none text-muted tracking-wider">
          ({{ reviewAmount }})
        </div>
      </div>
      <strong class="font-semibold text-center text-sm md:text-base flex-1 line-clamp-3 md:line-clamp-2 overflow-ellipsis">
        {{ product.name.default }}
      </strong>
      <div class="py-2">
        <ProductPriceDisplay
          class="text-sm flex-col @[280px]:flex-row items-center @[280px]:items-baseline"
          :is-starting-price="hasVariantPriceRange"
          :final-price="lowestVariantFinalPrice"
          :price="lowestVariantPrice"
        />
      </div>
      <div class="flex flex-col @[280px]:flex-row flex-wrap gap-x-4 gap-y-1">
        <StockStatus v-if="product.stock" :item="product" class="text-xs" />
        <SupplierStockStatus v-if="product.stock.supplierAvailability !== undefined" :is-available="product.stock.supplierAvailability.isAvailable" class="text-xs" />
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import type { ProductTileAlgolia } from '~/types/product'
import ReviewStars from '~/components/product/ReviewStars.vue'
import StockStatus from '~/components/product/StockStatus.vue'
import SupplierStockStatus from '~/components/product/SupplierStockStatus.vue'
import { usePrice } from '~/composables/usePrice'
import ProductLabels from '~/components/product/ProductLabels.vue'
import ProductDiscountBadge from '~/components/product/ProductDiscountBadge.vue'

const { getDiscountPercent } = usePrice()

const props = defineProps({
  product: {
    type: Object as () => ProductTileAlgolia,
    required: true
  }
})

const attrs = useAttrs()

const { getMainImageUrl } = useProduct()

const hasVariantPriceRange = computed(() => props.product?.variationPrices && props.product.variationPrices.lowest.finalPrice.inclTax !== props.product.variationPrices.highest.finalPrice.inclTax)
const lowestVariantFinalPrice = computed(() => props.product?.variationPrices ? props.product?.variationPrices.lowest.finalPrice : props.product?.finalPrice)
const lowestVariantPrice = computed(() => props.product?.variationPrices ? props.product?.variationPrices.lowest.price : props.product?.price)
const reviewScore = computed(() => props.product.yotpo?.reviewScore || 0)
const reviewAmount = computed(() => props.product.yotpo?.reviewAmount || 0)
const discountPercent = computed(() => getDiscountPercent(lowestVariantPrice.value.inclTax, lowestVariantFinalPrice.value.inclTax))

const cssClasses: ComputedRef<string> = computed(() => {
  return twMerge('@container h-full flex flex-col gap-3 group rounded bg-surface text-grey-950 hover:text-grey-950 hover:no-underline', attrs.class as string)
})
</script>
